export const visit = (vm, at) => {
vm(require("hubspot-prosemirror/lang/en.lyaml"));
vm(require("react-colorpicker/lang/en.lyaml"));
vm(require("ui-addon-upgrades/lang/en.lyaml"));
vm(require("payment-link-components/lang/en.lyaml"));
vm(require("framework-scoring-history-panel-lib/lang/en.lyaml"));
vm(require("commerce-subscription-cards-lib/lang/en.lyaml"));
vm(require("commerce-billing-lib/lang/en.lyaml"));
vm(require("crm-activity-creator-widgets/lang/en.lyaml"));
vm(require("crm-cards/lang/en.lyaml"));
vm(require("ui-addon-avatars/lang/en.lyaml"));
vm(require("sequences-crm-lib/lang/en.lyaml"));
vm(require("invoices-highlight-card-lib/lang/en.lyaml"));
at({ "en": { "crmObjectPreview": { "genericError": "Something's gone wrong. Please refresh the page and contact support if the problem persists.", "header": { "payments": "Payment details", "meetings": "Meeting details" }, "footer": { "save": "Save", "cancel": "Cancel", "viewRecord": "View record" } } } });
};