export const visit = (vm, at) => {
vm(require("commerce-subscription-cards-lib/lang/en.lyaml"));
vm(require("commerce-billing-lib/lang/en.lyaml"));
vm(require("crm-meetings-components-lib/lang/en.lyaml"));
vm(require("./communicator/en.lyaml"));
vm(require("./timeline/en.lyaml"));
vm(require("call-timeline-event/lang/en.lyaml"));
vm(require("call-widget-addon/lang/en.lyaml"));
vm(require("calling-internal-common/lang/en.lyaml"));
vm(require("calling-lifecycle-internal/lang/en.lyaml"));
vm(require("calling-orchestration-shared-library/lang/en.lyaml"));
vm(require("calling-ui-library/lang/en.lyaml"));
vm(require("customer-data-communicator/lang/en.lyaml"));
vm(require("customer-data-properties/lang/en.lyaml"));
vm(require("EmailSignatureEditor/lang/en.lyaml"));
vm(require("InboxConnectUtils/lang/en.lyaml"));
vm(require("MeetingsLib/lang/en.lyaml"));
vm(require("SalesContentIndexUI/lang/en.lyaml"));
vm(require("sequences-crm-lib/lang/en.lyaml"));
vm(require("ui-asset-management-lib/lang/en.lyaml"));
vm(require("crm-meetings-components-lib/lang/en.lyaml"));
vm(require("crm-timeline-tasks/lang/en.lyaml"));
at({ "en": { "placeholder": "Placeholder" } });
};