export const visit = (vm, at) => {
vm(require("UIComponents/lang/en.lyaml"));
vm(require("framework-data-table/lang/en.lyaml"));
vm(require("crm-cards-sdk/lang/en.lyaml"));
vm(require("crm-component-utils/lang/en.lyaml"));
vm(require("property-description-translator/lang/en.lyaml"));
vm(require("customer-data-filters/lang/en.lyaml"));
vm(require("customer-data-filters-ui/lang/en.lyaml"));
vm(require("ui-addon-teams/lang/en.lyaml"));
at({ "en": { "crmAssociationsTable": { "primary": "Primary", "empty": "No associated objects of this type exist or you don't have permission to view them.", "noResults": "No results found for the current filters.", "noAssociations": "No associations found.", "addAssociationButton": { "label": "Add", "permissionsError": "You don't have permission to create new associated objects of this type.", "permissionsTooltipText": "You need the Record Edit permission and CRM Association permission. Or, you need the Record Create permission. Ask an admin to update your access.", "error": "Something wen't wrong. Refresh the page to try again." }, "previewButton": { "title": "Preview", "noAccess": "You don't have permission to view this record. Please talk to your admin about changing your permissions." }, "dropdownButton": { "title": "Actions", "primary": "Remove as primary", "secondary": "Set as primary", "edit": "Edit association labels", "removeAssociation": "Remove association" }, "searchBar": { "characterLimitExceeded": "Search can't exceed {{ limit }} characters.", "wordLimitExceeded": "Search can't exceed {{ limit }} words." }, "labelFilter": { "manageLabels": "Manage association labels", "placeholder": "Association label" }, "propertyCell": { "update": { "error": "Property update failed. Please try again." } } } } });
};