export const visit = (vm, at) => {
vm(require("crm_ui/lang/en.lyaml"));
vm(require("crm_ui/lang/crm-internal-legacy/en.lyaml"));
vm(require("crm-events-components/lang/en.lyaml"));
vm(require("customer-data-objects/lang/en.lyaml"));
vm(require("customer-data-properties/lang/en.lyaml"));
vm(require("task-forms-lib/lang/en.lyaml"));
vm(require("task-queues/lang/en.lyaml"));
vm(require("sequences-crm-events-lib/lang/en.lyaml"));
vm(require("conversations-thread-view-crm-events/lang/en.lyaml"));
vm(require("call-timeline-event/lang/en.lyaml"));
at({ "en": { "timeline": { "compactTask": { "descriptionHeading": "Task Description", "detailsPopover": { "assignedTo": "Assigned to:", "queue": "Queue:" }, "optionsHeading": "Task Options", "status": { "due": "Due:", "overdue": "Overdue:" }, "updateDate": { "alertSuccess": "Task due date changed to {{ newDate }}" }, "error": { "getTaskFailed": "The task you're looking for does not exist or may have been deleted.", "getTaskFailedWithRestrictedPermissions": "There was a problem loading this task because it was deleted or you don't have permissions to view it." }, "disabledDeleteButton": "You can't delete this task because it is in your task queue." }, "timelineEditableSection": { "editButton": "Edit the following text:" }, "email": { "reviewAndSend": { "label": "Review and send" } }, "header": { "timestampTextTaskV2": "Due date" } } } });
};