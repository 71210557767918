export const visit = (vm, at) => {
vm(require("ui-shepherd-react/lang/en.lyaml"));
vm(require("growth-data-modal/lang/en.lyaml"));
vm(require("ui-universal-auth/lang/en.lyaml"));
vm(require("./modal/en.lyaml"));
vm(require("./common/en.lyaml"));
vm(require("./tours/testTour/en.lyaml"));
vm(require("./tours/academyTour/en.lyaml"));
vm(require("./tours/contactsImport/en.lyaml"));
vm(require("./tours/contactsTour/en.lyaml"));
vm(require("./tours/contactsAndContactRecordExplore1/en.lyaml"));
vm(require("./tours/contactsAndContactRecordExplore2/en.lyaml"));
vm(require("./tours/createContactFromSegmentationTour/en.lyaml"));
vm(require("./tours/createContactFromUserGuide/en.lyaml"));
vm(require("./tours/createDealOneTour/en.lyaml"));
vm(require("./tours/createDealTwoTour/en.lyaml"));
vm(require("./tours/createDealThreeTour/en.lyaml"));
vm(require("./tours/createEmailTourOne/en.lyaml"));
vm(require("./tours/createEmailTourTwoWithSend/en.lyaml"));
vm(require("./tours/createEmailTourTwoWithSendRedesign/en.lyaml"));
vm(require("./tours/createEmailTourThree/en.lyaml"));
vm(require("./tours/createEmailV1OneTour/en.lyaml"));
vm(require("./tours/createEmailV1TwoTour/en.lyaml"));
vm(require("./tours/createEmailV2OneTour/en.lyaml"));
vm(require("./tours/createEmailV2TwoTour/en.lyaml"));
vm(require("./tours/createEmailV2ThreeTour/en.lyaml"));
vm(require("./tours/createLandingPageOne/en.lyaml"));
vm(require("./tours/createLandingPageTwo/en.lyaml"));
vm(require("./tours/editDealStages/en.lyaml"));
vm(require("./tours/editDealStagesExtended/en.lyaml"));
vm(require("./tours/exploreEmailTemplateOne/en.lyaml"));
vm(require("./tours/exploreEmailTemplateTwo/en.lyaml"));
vm(require("./tours/filterContacts/en.lyaml"));
vm(require("./tours/filterContactsWithPropertySearch/en.lyaml"));
vm(require("./tours/goToGettingStartedTour/en.lyaml"));
vm(require("./tours/importedContactsPostSignup/en.lyaml"));
vm(require("./tours/importTour/en.lyaml"));
vm(require("./tours/newContactRecord/en.lyaml"));
vm(require("./tours/newContactTour/en.lyaml"));
vm(require("./tours/profileTour/en.lyaml"));
vm(require("./tours/publishAndEditContentTour/en.lyaml"));
vm(require("./tours/publishAndEditEditorTour/en.lyaml"));
vm(require("./tours/quickImportTour/en.lyaml"));
vm(require("./tours/createEmailListWithFilterTour/en.lyaml"));
vm(require("./tours/skipDataSyncSetupTour/en.lyaml"));
vm(require("./tours/editorLanguageVariationSelectorMoved/en.lyaml"));
vm(require("./tours/floatingSidebar/en.lyaml"));
vm(require("./tours/salesReporting/en.lyaml"));
vm(require("./tours/setupDealsPipeline/en.lyaml"));
vm(require("./tours/postImportContacts/en.lyaml"));
vm(require("./tours/postContactWizard/en.lyaml"));
vm(require("./tours/skipSetupContactsTour/en.lyaml"));
vm(require("./tours/postSetupContactsInAppSidePanelTour/en.lyaml"));
vm(require("./tours/postSetupContactsInAppSidePanelWithWelcomeTour/en.lyaml"));
vm(require("./tours/postSetupDealsInAppSidePanelTour/en.lyaml"));
vm(require("./tours/postSetupDealsInAppSidePanelWithWelcomeTour/en.lyaml"));
vm(require("./tours/postSetupTicketsInAppSidePanelTour/en.lyaml"));
vm(require("./tours/postSetupTicketsInAppSidePanelWithWelcomeTour/en.lyaml"));
vm(require("./tours/inAppSidePanelDealStagesTour/en.lyaml"));
vm(require("./tours/inAppSidePanelTicketStagesTour/en.lyaml"));
vm(require("./tours/inAppSidePanelViewsTour/en.lyaml"));
vm(require("./tours/inAppSidePanelPropertiesTour/en.lyaml"));
vm(require("./tours/inAppSidePanelListsTour/en.lyaml"));
vm(require("./tours/inAppSidePanelReportsDashboardTour/en.lyaml"));
vm(require("./tours/postFindCompanyWizard/en.lyaml"));
vm(require("./tours/postSetUpCrmDataSyncTour1/en.lyaml"));
vm(require("./tours/postSetUpCrmDataSyncTour2/en.lyaml"));
vm(require("./tours/createSegmentedList/en.lyaml"));
vm(require("./tours/setContactsAsMarketingContacts/en.lyaml"));
vm(require("./tours/connectSocialAccounts/en.lyaml"));
at({ "en": { "onboarding-tours": { "onboardingTourPlaceholderKey": "Placeholder" } } });
};