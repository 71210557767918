export const visit = (vm, at) => {
vm(require("commerce-subscription-cards-lib/lang/en.lyaml"));
vm(require("commerce-billing-lib/lang/en.lyaml"));
vm(require("crm-activity-creator-widgets/lang/en.lyaml"));
vm(require("property-description-translator/lang/en.lyaml"));
vm(require("customer-data-filters-ui/lang/en.lyaml"));
vm(require("crm-object-preview/lang/en.lyaml"));
vm(require("property-history-panel/lang/en.lyaml"));
vm(require("card-associated-objects-lib/lang/en.lyaml"));
vm(require("card-integration-card-lib/lang/en.lyaml"));
vm(require("card-engagement-attachments-lib/lang/en.lyaml"));
vm(require("crm-pipeline-approvals-lib/lang/en.lyaml"));
vm(require("predictive-scores-panel/lang/en.lyaml"));
vm(require("crm-record-access-management-lib/lang/en.lyaml"));
vm(require("crm-events-viz-timeline/lang/en.lyaml"));
vm(require("crm-activity-creator-interaction-window/lang/en.lyaml"));
vm(require("deal-splits-sidebar/lang/en.lyaml"));
vm(require("framework-scoring-history-panel-lib/lang/en.lyaml"));
vm(require("card-company-summary-lib/lang/en.lyaml"));
vm(require("customer-data-modals/lang/en.lyaml"));
vm(require("enrichment-properties-lib/lang/en.lyaml"));
vm(require("multi-account-crm-panel-lib/lang/en.lyaml"));
vm(require("crm-events-viz-core/lang/modals/en.lyaml"));
vm(require("merge-modal-lib/lang/en.lyaml"));
vm(require("panel-deal-registration-ai-summary-lib/lang/en.lyaml"));
at({ "en": { "crmCommandHandler": { "objectBuilder": { "successAlertTitle": "Success", "errorAlertTitle": "Error", "errorAlertMessage": "There was an issue saving your object or associations. Please refresh to try again and contact support if the problem persists.", "updatedAssociationsMessage": "Your associations were updated.", "createdObject": "Your {{objectTypeLabel}} was created.", "genericObjectLabel": "object" }, "workflowEnrollmentModal": { "success": { "title": "Success", "description": "This record was attempted to be enrolled in the workflow. See the workflow's enrollment logs for more details." }, "error": { "title": "Something went wrong", "description": "There was an issue enrolling this record in that workflow. Please try again and contact support if the problem persists." } } } } });
};